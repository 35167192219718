import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "./style.scss"
const aboutPage = ({ title, describe, content, style, image, mbImage }) => {
  return (
    <div id="textImage">
      <div className="row justify-content-center align-items-xl-start align-items-center">
        <div className="textTitle d-md-none d-block">{title}</div>
        <div
          className={
            style == "textImage"
              ? "order-md-5 col-md-6 col-12 image"
              : "col-md-6 col-12 image"
          }
        >
          {image ? (
            <GatsbyImage
              className={mbImage ? "d-md-block d-none" : ""}
              image={image}
              alt={title}
              title={title}
            />
          ) : (
            ""
          )}
          {mbImage ? (
            <GatsbyImage
              className="d-md-none d-block"
              image={mbImage}
              alt={title}
              title={title}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className={
            style == "textImage"
              ? "text-md-right col-md-6 col-12 text "
              : "col-md-6 col-12 text"
          }
        >
          <div className="clearfix">
            <div
              className={
                style == "textImage"
                  ? "float-right textTitle d-md-block d-none"
                  : "textTitle d-md-block d-none"
              }
              style={{ width: "80px" }}
            >
              {title}
            </div>
          </div>
          <div className="textDescribe">{describe}</div>
          <div className="textContent">{content}</div>
        </div>
      </div>
    </div>
  )
}
export default aboutPage
